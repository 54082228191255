<!-- App.vue -->
<template>
  <section>
    <div
      v-if="!loading"
      class="font-sans text-text bg-background min-h-screen flex flex-col"
    >
      <router-view></router-view>
    </div>
    <div>
      <LoaderComp :loading="loading" />
    </div>
  </section>
</template>

<script setup>
import { onMounted } from "vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

onMounted(async () => {
  // Fancybox init.
  let fancyboxELems = [
    "[data-fancybox='default']",
    "[data-fancybox='gallery']",
  ];
  // for in loop.
  for (let i = 0; i < fancyboxELems.length; i++) {
    Fancybox.bind(fancyboxELems[i], {
      caption: function (fancybox, carousel, slide) {
        return slide.caption || "";
      },
    });
  }
});

</script>
