import { createRouter, createWebHistory, Router, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import routes from './routes'
// Nprogress
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'

const router : Router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(
async  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    // Start the animation of the route progress bar.
    Nprogress.start()
    next()
  }
)

router.afterEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized
  ) => {
    // Complete the animation of the route progress bar.
    Nprogress.done()
  }
)

export default router
