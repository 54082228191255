import { RouteRecordRaw } from 'vue-router'

const siteRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/HomePage.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/pages/FaqPage.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('@/pages/SuccessPage.vue')
  },

  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/pages/ProjectPage.vue')
  },
  {
    path: '/project/:id',
    name: 'ProjectDetail',
    component: () => import('@/pages/ProjectDetailPage.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/pages/AboutPage.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/pages/ServicePage.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/pages/ContactPage.vue')
  },
  {
    path: '/site/:slug',
    name: 'site-page',
    component: () => import('@/pages/Site/SitePage.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/pages/UnauthorizedPage.vue')
  },
]

export default siteRoutes
